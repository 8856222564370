import { useEffect } from "react";
import "./styles.css";

export const NotFound = () => {
  useEffect(() => {
    setTimeout(() => {
      const nfError = new Error('Ошибка! Страница не найдена')
      nfError.code = 404
      throw nfError
    }, 100)
  })

  return (
    <div className="page">
      <div className="error">
        <p className="error__code">404</p>
        <h1 className="error__title">Not Found</h1>
      </div>
    </div>
  );
};
