import React from "react"
import { useSelector } from "react-redux/es/hooks/useSelector"
import { useParams, NavLink, useLocation } from "react-router-dom"

import Header from "../Header/Header"
import EmployeeBlock from "../EmployeeBlock/EmployeeBlock"
import Block from "../Block/Block"
import JoinUsForm from "../JoinUsForm/JoinUsForm"
import Footer from "../Footer/Footer"

import { publicGalleryURL } from "../../utils/constants/webConfig"

const TeamInnerPage = () => {
    const { id } = useParams()
    const location = useLocation()

    const meta = useSelector((state) => state.meta)
    const language = meta.language

    const pagesState = useSelector((state) => state.pages)
    const practiceAreas = pagesState.practiceAreas[language]
    const news = pagesState.news[language]
    const employee = pagesState.employee[language][id]

    const teamPage = pagesState.teamPage
    const advBlock = teamPage[language][0].advBlock

    const mainPageBreadcrumn = {
        ru: "Главная",
        en: "Main page",
        kz: "Негізгі бет",
    }

    const teamPageBreadcrumb = {
        ru: "Команда",
        en: "Team",
        kz: "Команда",
    }

    const newsPageBreadcrumb = {
        ru: "Новости",
        en: "News",
        kz: "Жаңалықтар",
    }

    function getBreadCrumb() {
        console.log(location?.state?.prevPath)
        if (!location?.state?.prevPath || location?.state?.prevPath === "/") return <NavLink to={location?.state?.prevPath || "/"} style={{ textDecoration: "none", color: "inherit" }}> {mainPageBreadcrumn[language]} </NavLink>
        if (location?.state?.prevPath === "/team") return <NavLink to={location.state.prevPath} style={{ textDecoration: "none", color: "inherit" }}> {teamPageBreadcrumb[language]} </NavLink>
        if (location?.state?.prevPath === "/news") return <NavLink to={location.state.prevPath} style={{ textDecoration: "none", color: "inherit" }}> {newsPageBreadcrumb[language]} </NavLink>


        if (location?.state?.prevPath?.includes("practice-areas")) {
            const practiceAreaID = location.state.prevPath.replace("/practice-areas/", "")

            return (<NavLink to={location.state.prevPath} style={{ display: "inline-block", textDecoration: "none", color: "inherit", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", maxWidth:"60%" }}> {practiceAreas[practiceAreaID].title} </NavLink>)
        }

        if (location?.state?.prevPath?.includes("news")) {
            const newsID = location.state.prevPath.replace("/news/", "")

            return (<NavLink to={location.state.prevPath} style={{ display: "inline-block", textDecoration: "none", color: "inherit", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", maxWidth:"60%" }}> {news[newsID].title} </NavLink>)
        }
    }

    return (
        <>
            <Header page="mainPage" color="white" style={{ marginBottom: "70px" }} border="true" />
            <EmployeeBlock employee={employee} style={{ marginBottom: '80px' }}>
                <div className="bread-crumbs bread-crumbs__team-inner">
                    {getBreadCrumb()}
                    &nbsp;/&nbsp;<span className="bread-crumbs__after">{employee.name}</span>
                </div>
            </EmployeeBlock>
            <Block type="form" style={{ marginBottom: "50px" }} text={[]}>
                <JoinUsForm />
            </Block>
            <Block type="advertisement" style={{ marginBottom: "100px" }} innerImage={`${publicGalleryURL}${advBlock?.image?.data?.attributes?.url}`} text={[advBlock.text]} button={advBlock.button} buttonText={advBlock.buttonText} />
            <Footer />
        </>
    )
}

export default TeamInnerPage