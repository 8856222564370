import './App.css';
import React from 'react';
import MainPage from '../MainPage/MainPage';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Routes, useLocation } from 'react-router-dom';
import PracticeAreasPage from '../PracticeAreasPage/PracticeAreasPage';
import PracticeAreaInnerPage from '../PracticeAreaInnerPage/PracticeAreaInnerPage';
import TeamPage from '../TeamPage/TeamPage';
import TeamInnerPage from '../TeamInnerPage/TeamInnerPage';
import NewsPage from '../NewsPage/NewsPage';
import ContactsPage from '../ContactsPage/ContactsPage';
import BurgerMenuPopup from "../BurgerMenuPopup/BurgerMenuPopup";
import { toggleBurgerPopup } from '../../state/popupStatus/popupStatusSlice';
import NewsInnerPage from '../NewsInnerPage/NewsInnerPage';
import Loader from '../Loader/Loader';
import { setIsLoading } from '../../state/meta/metaSlice';
import { NotFound } from '../Errors/NotFound';

function App() {
  const language = useSelector((state) => state.meta.language)

  const dispatch = useDispatch()
  const isBurgerMenuOpen = useSelector((state) => state.popups.isBurgerPopupOpen)

  const pathname = useLocation()
  const pagesSlice = useSelector((state) => state.pages)
  async function getData() {
    if (!pagesSlice?.mainPromo[language]?.title && pagesSlice.practiceAreas.length == 0 && pagesSlice.employee.length == 0 && pagesSlice.news.length == 0) {
      return await dispatch({ type: "GET-MAIN-PAGE" })
    }

    dispatch(setIsLoading(false))
  }

  React.useEffect(() => {
    window.scrollTo(0, 0)
    getData()
  }, [pathname])

  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Loader element={MainPage} />} />
        <Route path="/practice-areas" element={<Loader element={PracticeAreasPage} />} />
        <Route path="/practice-areas/:id" element={<Loader element={PracticeAreaInnerPage} />} />
        <Route path="/team" element={<Loader element={TeamPage} />} />
        <Route path="/team/:id" element={<Loader element={TeamInnerPage} />} />
        <Route path="/news" element={<Loader element={NewsPage} />} />
        <Route path="/news/:id" element={<Loader element={NewsInnerPage} />} />
        <Route path="/contact-us" element={<Loader element={ContactsPage} />} />
        <Route path="*" element={<NotFound />} />
      </Routes>

      {
        isBurgerMenuOpen
        &&
        <BurgerMenuPopup isOpened={isBurgerMenuOpen} onClose={(evt) => { evt.stopPropagation(); dispatch(toggleBurgerPopup()) }} />
      }
    </div>
  );
}

export default App;
